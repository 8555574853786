import $ from "jquery"

interface IChatResponse {
    message: string
}

export function useApi(callback: (api: Api) => void): Promise<{}> {
    return new Promise(() => {
        var api = new Api();
        callback(api);
    });
}

export class Api {
    private async getAsync<T>(uri: string): Promise<T> {
        return (await this.callApiAsync(uri, "GET", {})) as T;
    }

    private async postAsync<T>(uri: string, data: any): Promise<T> {
        return (await this.callApiAsync(uri, "POST", data)) as T;
    }

    static getServiceUrl(uri: string): string {
        if (!((uri as any).startsWith("/")))
            uri = "/" + uri;
        return "https://api.sidekicksupport.co.uk" + uri;
    }

    async callApiAsync(uri: string, method: string, data: any): Promise<{}> {
        var url = Api.getServiceUrl(uri);
        var result = await $.ajax({
            url: url,
            method: method,
            crossDomain: true,
            data: JSON.stringify(data),
            dataType: "json",
            contentType: "application/json",
            error: (jqXhr: any, status: string, error: string) => {
                alert(error);
                return;
            },
            complete: () => {
                //Busy.instance.complete(busy);
            }
        });

        if (result == null || !(result.hasErrors)) {
            return result;
        } else {
            alert(result.errors);
            throw new Error("Server call failed with errors.");
        }
    }

    chatAsync(message: string): Promise<IChatResponse> {
        return this.postAsync<IChatResponse>("/chat", {
            message: message
        });
    }
}