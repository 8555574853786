import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';

import { Api, useApi } from "./services/api";

interface IAppState {
    foo?: string;
    message?: string;
}

function App() {

    var [state, setState] = useState<IAppState>();
    var [response, setResponse] = useState<string>();

    var api: Api;

    useApi(async (theApi) => {
        api = theApi;
        if (state == null) {
            var theState: IAppState = {
                foo: "Retain"
            };
            setState(theState);
        }
    });

    const patchState = (name: string, value: any) => {
        (state as any)[name] = value;
        setState(state);
    }

    const send = async () => {
        var response = await api.chatAsync(state!.message as string);
        console.log("response --> ", response.message);
        setResponse(response.message);
    };

    if (state != null) {
        return (
            <div className="App">
                <header className="App-header">
                </header>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Chat away..." value={state.message}
                        onChange={(e) => patchState("message", e.target.value)} />
                </div>
                <div className="form-group">
                    <button className="btn btn-success" onClick={send}>Send</button>
                </div>

                <br />
                <br />
                <br />
                <br />
                <br />

                <div>
                    {response}
                </div>

            </div>
        );
    } else {
        return (
            <div>Waiting for state...</div>
        )
    }
}

export default App;
